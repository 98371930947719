<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">Create a new device from template</h4>
      <p class="category">Create a device</p>
    </md-card-header>

    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col col-12 col-md-4">
              <div class="row d-flex m-0 align-center">
                <div class="col col-10">
                  <TGCTextField
                    label="Alias"
                    v-model="form.alias"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.alias") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col col-12 col-md-4">
              <div class="row d-flex m-0 align-center">
                <div class="col col-10">
                  <TGCTextField
                    label="Identifier"
                    v-model="form.identifier"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.identifier") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col col-12 col-md-4">
              <div class="row d-flex m-0 align-center">
                <div class="col col-10">
                  <TGCTextField
                    label="MAC"
                    v-model="form.mac"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.mac") }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-form>
        <div class="row">
          <div class="col-12 text-right">
            <tgl-save-button
              ref="saveBtn"
              :entity="entity"
              :dispatch="entity.dispatch"
              :action="'creado'"
              :redirect="entity.redirect"
              :validateForm="handleSubmit"
            ></tgl-save-button>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import { mapGetters } from "vuex";
import form from "@/mixins/form";
export default {
  components: {},
  name: "NewTemplateDashboard",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "ow",
    },
  },
  data: () => ({
    form: {
      alias: "",
      identifier: "",
      mac: "",
    },
    editMode: false,
  }),
  mounted() {},
  computed: {
    ...mapGetters(["getTheme"]),
    entity() {
      let params = {
        deviceTemplate: this.$route.params.deviceTemplate,
        device: {
          deviceTemplate: this.$route.params.deviceTemplate,
          alias: this.form.alias,
          identifier: this.form.identifier,
          mac: this.form.mac,
        },
      };
      return {
        name: "plantilla",
        params,
        dispatch: "template/createDeviceFromTemplate",
        redirect: { name: "templates" },
      };
    },
  },
  methods: {
    handleSubmit() {
      return this.$refs.form.validate();
    },
  },
};
</script>