var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-card',[_c('md-card-header',{attrs:{"data-background-color":_vm.getTheme}},[_c('h4',{staticClass:"title"},[_vm._v("Create a new device from template")]),_c('p',{staticClass:"category"},[_vm._v("Create a device")])]),_c('md-card-content',[_c('div',{staticClass:"container-fluid"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-4"},[_c('div',{staticClass:"row d-flex m-0 align-center"},[_c('div',{staticClass:"col col-10"},[_c('TGCTextField',{attrs:{"label":"Alias","rules":[
                    _vm.rules.required,
                    (value) => _vm.rules.minLength(value, 0),
                    (value) => _vm.rules.maxLength(value, 100),
                  ]},model:{value:(_vm.form.alias),callback:function ($$v) {_vm.$set(_vm.form, "alias", $$v)},expression:"form.alias"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.alias")))])])],1)]),_c('div',{staticClass:"col col-12 col-md-4"},[_c('div',{staticClass:"row d-flex m-0 align-center"},[_c('div',{staticClass:"col col-10"},[_c('TGCTextField',{attrs:{"label":"Identifier","rules":[
                    _vm.rules.required,
                    (value) => _vm.rules.minLength(value, 0),
                    (value) => _vm.rules.maxLength(value, 100),
                  ]},model:{value:(_vm.form.identifier),callback:function ($$v) {_vm.$set(_vm.form, "identifier", $$v)},expression:"form.identifier"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.identifier")))])])],1)]),_c('div',{staticClass:"col col-12 col-md-4"},[_c('div',{staticClass:"row d-flex m-0 align-center"},[_c('div',{staticClass:"col col-10"},[_c('TGCTextField',{attrs:{"label":"MAC","rules":[
                    _vm.rules.required,
                    (value) => _vm.rules.minLength(value, 0),
                    (value) => _vm.rules.maxLength(value, 100),
                  ]},model:{value:(_vm.form.mac),callback:function ($$v) {_vm.$set(_vm.form, "mac", $$v)},expression:"form.mac"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.mac")))])])],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('tgl-save-button',{ref:"saveBtn",attrs:{"entity":_vm.entity,"dispatch":_vm.entity.dispatch,"action":'creado',"redirect":_vm.entity.redirect,"validateForm":_vm.handleSubmit}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }